import React from 'react';
import { ConfigProvider, notification } from 'antd';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';

const notifyError = notification.error;
export function Views() {
  const {
    theme: { locale, direction }
  } = useSelector((state) => ({
    theme: state.theme
  }));

  const currentAppLocale = resources[locale];
  useBodyClass(`dir-${direction}`);

  let isForbiddenError = true;

  notification.error = (error) => {
    const { errorStatus } = error;

    if (errorStatus === 403 && isForbiddenError) {
      notifyError(error);
      isForbiddenError = false;
      return;
    }
    if (![401, 403].includes(errorStatus)) {
      notifyError(error);
    }
  };
  return (
    <ConfigProvider direction={direction} locale={currentAppLocale.antd}>
      <Routes />
    </ConfigProvider>
  );
}

export default Views;
